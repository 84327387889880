// 1.61_08-Sep-2023 11-05
const srRS= {
    login: {
      title: 'Пријава',
      sign_in_btn: 'Пријава'
    },
    bar: {
        logout: 'Одјава',
        languages: 'Језици',
        settings: 'Отворите подешавања'
    },
    language: {
      name: 'српски (RS)',
    },
    attributePage: {
        title: 'Измене карактеристика',
        viewChanges: 'Прикажи измене',
        dateTime: 'Датум',
        notificationType: 'Врста нотификације',
        notificationValue: 'Вредност нотификације',
        oldValue: 'Стара вредност',
        newValue: 'Нова вредност',
        accept: 'Прихвати',
        reject: 'Одбиј',
    },
    Home: 'Почетна страница',
    load: 'Почетно учитавање у току...',
    Requested: "Захтевано",
    Viewed: "Прегледано",
    Executing: "Извршавање у току",
    Completed: "Завршено",
    Expired: "Истекло",
    Cancelled: "Отказано",
    Error: "Грешка",
    Rejected: "Одбијено",
    error: 'Нешто није у реду; покушајте поново касније.',
    displayname: 'Име за приказ',
    givenname: 'Име',
    surname: 'Презиме',
    email: 'Емаил',
    mail: 'Емаил',
    name: 'Име',
    firstname: 'Име',
    lastname: 'Презиме'
  };
  
  export default srRS;
  