import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { loginRequest, getCurrentLanguage } from "../../services/authConfig";
import { useMsal,  } from "@azure/msal-react";
import { useLocales } from "../../locales";
import MenuPopover from "../menu-popover";
import { Stack, MenuItem, IconButton } from "@mui/material";
import Image from "../../components/image";
import { useState } from "react";
import { PopupRequest } from "@azure/msal-browser";

export default function Login() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { instance } = useMsal();
  const { translate, allLangs, currentLang, onChangeLang,  } = useLocales();
  
  const handleLogin = () => {

    //if (!loginRequest)
    //{
      const loginReq: PopupRequest = {
        scopes: ["openid"],    
        extraQueryParameters: {
            ui_locales: getCurrentLanguage(),
        },
      }; 
          
      instance.loginPopup(loginReq).catch((e) => {
        console.log(e);
      });
   // }
   // else {
   //   instance.loginPopup(loginRequest).catch((e) => {
   //     console.log(e);
   //   });
   // }
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang: string) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        whiteSpace: "nowrap",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {`${translate("login.title")}`}
      </Typography>
      <Button
        onClick={() => handleLogin()}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {`${translate("login.sign_in_btn")}`}
      </Button>
      <>
        <IconButton
          onClick={handleOpenPopover}
          sx={{
            width: 40,
            height: 40,
            ...(openPopover && {
              bgcolor: "action.selected",
            }),
          }}>
          <Typography textAlign="center">
                {`${translate("bar.languages")}`}
                </Typography>
        </IconButton>
        <MenuPopover
          open={openPopover}
          onClose={handleClosePopover}
          sx={{ width: 220 }}
        >
          <Stack spacing={0.75} justifyContent={ "left"}>
            {allLangs.filter(lang => {return lang.label !== ""}).map((option) => (
              <MenuItem
                key={option.value}
                selected={option.value === currentLang.value}
                onClick={() => handleChangeLang(option.value)}
              
              >
                {/* <Image
                  disabledEffect
                  alt={option.label}
                  src={option.icon}
                  sx={{ width: 28, mr: 2 }}
                /> */}

                {option.label}
              </MenuItem>
            ))}
          </Stack>
        </MenuPopover>
      </>
    </Box>
  );
}
