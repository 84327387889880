import { useAccount, useMsal } from '@azure/msal-react';
import axios from 'axios';
import { protectedResources } from '../../services/authConfig';

export default function RequestInterceptor(props: any) {
    const { instance, accounts } = useMsal();
  
    const account = useAccount(accounts[0]);

    axios.interceptors.request.use(async (config) => {

        if (!account) {
            throw Error('No active account! Verify a user has been signed in.');
        }
        
        const response = await instance.acquireTokenSilent({
            ...protectedResources,
            account,
        });

        const bearer = `Bearer ${response.accessToken}`;
        config.headers.Authorization = bearer;
        config.headers['idToken'] = response.idToken;
        config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_APIM_SUB_KEY;

        return config;
    });

  return (
    <>
        {props.children}
    </>
  );
}