import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

export default function Progress(props: any) {
    const {visible} = props;
  return (
    <Box sx={{ width: '100%' }}>
        { visible && <LinearProgress color="secondary" />}
    </Box>
  );
}