import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TransactionService, {
  AttributeChangeHeaderStatus,
  B2CTransaction,
} from "../../services/transactionService";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card/Card";
import {
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  IconButtonProps,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Box } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Progress from "../progress/Progress";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Icon from '@mui/material/Icon';
import { getAttributeContent } from "./AttributeNameMap";
import { useLocales } from "../../locales";

const tokenParamName = "attToken";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export interface AttributeCheckProps {
  isExpanded: boolean;
  transactionProp: B2CTransaction | null;
}

export default function AttributeCheck({
  isExpanded = false,
  transactionProp,
}: AttributeCheckProps) {
  const [tokenParam, setToken] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const isAuthenticated = useIsAuthenticated();
  const [transaction, setTransaction] =
    useState<B2CTransaction | null>(transactionProp);
  const [expanded, setExpanded] = useState(isExpanded);
  const [expandVisible, setExpandVisible] = useState(true);
	const [progressVisible, setProgressVisible] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setViewed(transaction?.attributeChangeHeader.transactionId || "");
  };

  
  const { translate, allLangs, currentLang, onChangeLang } = useLocales();

  useEffect(() => {
    const token = searchParams.get(tokenParamName);
    if (token) {
      setToken(token);
      setSearchParams();
    }
  }, [tokenParam, searchParams, setSearchParams]);

  useEffect(() => {
    if (tokenParam && isAuthenticated) {      

      setProgressVisible(true); 

      (async function loadTransactionData() {
        setTransaction(
          (await TransactionService.getTransaction(tokenParam)).data
        );

        setExpanded(true);

        setExpandVisible(false);
    
        setProgressVisible(false);
      })();
    }
  }, [tokenParam, isAuthenticated]);

  const setTransactionStatus = async (
    transactionId: string,
    accepted: boolean
  ) => {
    
    setProgressVisible(true);

    await TransactionService.updateTransactionStatus(transactionId, accepted);

    const data = await TransactionService.getTransaction(transactionId);

    setTransaction(data.data);

    setProgressVisible(false);
  };

  const handleExited = () => {
    setViewed(transaction?.attributeChangeHeader.transactionId || "");
  };

  const setViewed = async (transactionId: string) => {
    
    setProgressVisible(true);

    //================================================
    // Fix for the issue that the transaction is set to Viewed even if it's in status 500
    // Set to Viewed only if it's in status 100
    if (transaction?.attributeChangeHeader.status === 100)
    {
      await TransactionService.setViewed(transactionId);
      const data = await TransactionService.getTransaction(transactionId);
      setTransaction(data.data);
    }
    //================================================
    
    setProgressVisible(false);
  };

  return (
    <>
      <Progress visible={progressVisible}/>
      {transaction && (
        <Box sx={{ m: 2 }}>
          
          <Card>
            <CardHeader
              title={`${translate("attributePage.title")}`}
              subheader={`${translate(AttributeChangeHeaderStatus[transaction.attributeChangeHeader.status])}`}
              action={
                <>

                </>
              }
            ></CardHeader>
                <Grid container>
                <Box display="flex" justifyContent="flex-end" >
                  <Grid xs={12} sm={8} >      
                  
                    <Button variant="contained" color="success"
                      style={{ width: 90, marginRight: 0, marginTop: 10 }}
                      onClick={async () => {
                        await setTransactionStatus(transaction.attributeChangeHeader.transactionId, true);
                      }}
                      disabled={
                        progressVisible || transaction.attributeChangeHeader.status > 200
                      }
                    >
                      {`${translate("attributePage.accept")}`}
                    </Button>

                  </Grid>
                  <Grid xs={11} sm={7} >

                  <Button variant="contained" color="error"
                    style={{ width: 90,  marginRight: 0, marginTop: 10 }}
                    onClick={async () => {
                      await setTransactionStatus(transaction.attributeChangeHeader.transactionId, false);
                    }}
                    
                    disabled={
                      progressVisible || transaction.attributeChangeHeader.status > 200
                    }
                  >
                    {`${translate("attributePage.reject")}`}
                  </Button>
                  
                    </Grid>
                    </Box>
                </Grid>
            <CardContent>
              <Grid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid xs={12} sm={6} >
                  <Box marginTop={1}
                  // marginRight={1}
                  border={0}
                  textAlign={"left"}
                  color={'text.secondary'}>
                    {`${translate("attributePage.dateTime")}`}
                  </Box>
                </Grid>
                <Grid xs={12} sm={6}>
                  <Box marginTop={1}
                  border={0}
                    fontWeight={'bold'} >
                    {new Date(
                      transaction.attributeChangeHeader.notificationSentTime
                    ).toLocaleString()}
                  </Box>
                </Grid>
                <Grid xs={12} sm={6}>
                  <Box marginTop={1} 
                  // marginRight={1}
                  border={0}
                  textAlign={"left"}
                  color={'text.secondary'}>
                    {`${translate("attributePage.notificationType")}`}
                  </Box>
                </Grid>
                <Grid xs={12} sm={6}>
                  <Box marginTop={1}
                  border={0}
                    fontWeight={'bold'}>
                    {`${translate(transaction.attributeChangeHeader.notificationType.toLowerCase())}`}
                  </Box>
                </Grid>
                <Grid xs={12} sm={6}>
                  <Box marginTop={1} 
                  // marginRight={1}
                  textAlign={"left"}
                  border={0}
                  color={'text.secondary'}>
                    {`${translate("attributePage.notificationValue")}`}
                  </Box>
                </Grid>
                <Grid xs={12} sm={6}>
                  <Box marginTop={1}
                  border={0}
                    fontWeight={'bold'}
                    >
                    {transaction.attributeChangeHeader.notificationValue}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            {expandVisible ? <CardActions disableSpacing sx={{bgColor:'#fafafa'}}>
              
              <h2>{`${translate("attributePage.viewChanges")}`}</h2>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"

                style={{
                  textAlign: 'center',
                  border: 1, margin: 10, padding: 10, borderRadius: 10, borderColor: '#128474', backgroundColor: '#128474', color: 'white', borderWidth: 2, borderStyle: 'solid'
                }}
              ><Button/><KeyboardDoubleArrowDownIcon /><Button/>
              </ExpandMore>
              
            </CardActions> : <></>
            }
            <Collapse in={expanded} timeout="auto" unmountOnExit 
            >
              {transaction.attributeChangeDetails.map((detail) => (
                <Card sx={{ m: 2 }} key={detail.id}>
                  <CardHeader title={getAttributeContent(detail.attributeName)} />
                  <CardContent>
                    <Grid container>
                      <Grid xs={12} sm={2}>
                        <Box marginTop={1}
                  color={'text.secondary'}>
                          {`${translate("attributePage.oldValue")}`}
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={4}>
                        <Tooltip title={`${translate("attributePage.oldValue")}`}>
                          <Box
                            width="100%"
                            border={0}
                            borderColor="error.main"
                            p={1}
                            display="inline-block"
                            paddingRight={10} 
                            fontWeight={'bold'}
                          >
                            {detail.oldValue}
                          </Box>
                        </Tooltip>
                      </Grid>
                      <Grid xs={1} sm={0} >
                      </Grid>
                      <Grid xs={12} sm={2}>
                        <Box marginTop={1}
                  color={'text.secondary'}>
                          {`${translate("attributePage.newValue")}`}
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={4}>
                        <Tooltip title={`${translate("attributePage.newValue")}`}>
                          <Box width="100%"
                            border={0}
                            borderColor="success.main"
                            p={1}
                            display="inline-block" 
                            fontWeight={'bold'}
                          >
                            {detail.newValue}
                          </Box>
                        </Tooltip>
                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Collapse>
          </Card>
        </Box>
      )}
    </>
  );
}


