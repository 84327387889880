import { useIsAuthenticated } from '@azure/msal-react';
import { Box } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import React, { useEffect, useState } from 'react'
import AttributeCheck from '../../components/attributeCheck/AttributeCheck';
import Progress from '../../components/progress/Progress';
import TransactionService, { TransactionsResult } from '../../services/transactionService';

interface PaginationState {
  page: number,
  size: number
}

export default function HomePage() {

  const isAuthenticated = useIsAuthenticated();
  const [transactionResult, settransactionResult] =
    useState<TransactionsResult>();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState | null>(null);
	const [progressVisible, setProgressVisible] = useState(false);

  useEffect(() => {
      setProgressVisible(true);
    if (isAuthenticated) {
      setProgressVisible(true);
      (async function loadTransactionData() {
        settransactionResult(
          (await TransactionService.getTransactions())
        );
        setProgressVisible(false);
      })();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if(paginationState){
      setProgressVisible(true);
      (async function loadTransactionData() {
        settransactionResult(
          (await TransactionService.getTransactions(paginationState.page, paginationState.size))
        );
        setProgressVisible(false);
      })();
    }
  }, [paginationState]);
  
  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setPaginationState({page: newPage, size: rowsPerPage});
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newSize = parseInt(event.target.value, 10);
    setRowsPerPage(newSize);
    setPage(0);
    setPaginationState({page: 0, size: newSize});
  };

  return (
    <Box>
      <Progress visible={progressVisible}/>
      
      {transactionResult?.data.map(transaction => 
        <AttributeCheck key={transaction.attributeChangeHeader.id} isExpanded={false} transactionProp={transaction} />
      )}
      {transactionResult !== undefined && transactionResult.count > 10? 
        <TablePagination component="div" count={transactionResult?.count} rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage} page={page} onPageChange={handlePageChange} /> : <></>}
    </Box>
  )
}
