const itIT= {
    login: {
      title: 'Accedi',
      sign_in_btn: 'Accedi'
    },
    bar: {
        logout: 'Esci',
        languages: 'Lingue',
        settings: 'Apri le impostazioni'
    },
    language: {
      name: 'Italiano (IT)',
    },
    attributePage: {
        title: 'Modifiche degli attributi',
        viewChanges: 'Vedi modifiche',
        dateTime: 'Data',
        notificationType: 'Tipo di notifica',
        notificationValue: 'Valore notifica',
        oldValue: 'Vecchio valore',
        newValue: 'Nuovo valore',
        accept: 'Accetta',
        reject: 'Rifiuta',
    },
    Home: 'Pagina iniziale',
    load: 'Caricamento iniziale in corso...',
    Requested: "Richiesto",
    Viewed: "Visionato",
    Executing: "In esecuzione",
    Completed: "Completato",
    Expired: "Scaduto",
    Cancelled: "Annullato",
    Error: "Errore",
    Rejected: "Rifiutato",
    error: 'Qualcosa è andato storto; riprovi più tardi.',
    displayname: 'Nome visualizzato',
    givenname: 'Nome',
    surname: 'Cognome',
    email: 'E-mail',
    mail: 'E-mail',
    name: 'Nome',
    firstname: 'Nome',
    lastname: 'Cognome',
    footer_copyright: 'Tutti i diritti riservati.'
  };
  
  export default itIT;
  