import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { defaultLang } from './config-lang';
//
import arILLocales from './langs/ar-IL';
import bgBGLocales from './langs/bg-BG';
import deDELocales from './langs/de-DE';
import enAULocales from './langs/en-AU';
import enCALocales from './langs/en-CA';
import enGBLocales from './langs/en-GB';
import enIELocales from './langs/en-IE';
import enUSLocales from './langs/en-US';
import esESLocales from './langs/es-ES';
import esUSLocales from './langs/es-US';
import frCALocales from './langs/fr-CA';
import frFRLocales from './langs/fr-FR';
import heILLocales from './langs/he-IL';
import itITLocales from './langs/it-IT';
import koKRLocales from './langs/ko-KR';
import nlNLLocales from './langs/nl-NL';
import plPLLocales from './langs/pl-PL';
import ptBRLocales from './langs/pt-BR';
import ptPTLocales from './langs/pt-PT';
import srRSLocales from './langs/sr-RS';
import svSELocales from './langs/sv-SE';
import arLocales from './langs/ar';
import cnLocales from './langs/cn';



// ----------------------------------------------------------------------

let lng = defaultLang.value;
let resources ={
  'ar-IL': { translations: arILLocales },
  'bg-BG': { translations: bgBGLocales },
  'he-IL': { translations: heILLocales },
  'de-DE': { translations: deDELocales },
  'en-AU': { translations: enAULocales },  
  'en-CA': { translations: enCALocales },
  'en-GB': { translations: enGBLocales },
  'en-IE': { translations: enIELocales },
  'en-US': { translations: enUSLocales },
  'es-ES': { translations: esESLocales },
  'es-US': { translations: esUSLocales },
  'fr-CA': { translations: frCALocales },
  'fr-FR': { translations: frFRLocales },
  'it-IT': { translations: itITLocales },
  'ko-KR': { translations: koKRLocales },
  'nl-NL': { translations: nlNLLocales },
  'pl-PL': { translations: plPLLocales },
  'pt-BR': { translations: ptBRLocales },
  'pt-PT': { translations: ptPTLocales },
  'sr-RS': { translations: srRSLocales },
  'sv-SE': { translations: svSELocales },
  'ar': { translations: arILLocales },
  'bg': { translations: bgBGLocales },
  'he': { translations: heILLocales },
  'de': { translations: deDELocales },
  'en': { translations: enUSLocales },
  'es': { translations: esUSLocales },
  'fr': { translations: frFRLocales },
  'it': { translations: itITLocales },
  'ko': { translations: koKRLocales },
  'nl': { translations: nlNLLocales },
  'pl': { translations: plPLLocales },
  'pt': { translations: ptPTLocales },
  'sr': { translations: srRSLocales },
  'sv': { translations: svSELocales },
}
const storageAvailable = localStorageAvailable();

if (storageAvailable) {
  lng = localStorage.getItem('i18nextLng') || defaultLang.value;
}

const urlParams = new URLSearchParams(window.location.search);
let urlLang = urlParams.get('lang');

if (urlLang && Object.keys(resources).includes(urlLang)) {
  lng = urlLang;
  localStorage.setItem('i18nextLng', lng);
 }

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'ar-IL': { translations: arILLocales },
      'bg-BG': { translations: bgBGLocales },
      'he-IL': { translations: heILLocales },
      'de-DE': { translations: deDELocales },
      'en-AU': { translations: enAULocales },  
      'en-CA': { translations: enCALocales },
      'en-GB': { translations: enGBLocales },
      'en-IE': { translations: enIELocales },
      'en-US': { translations: enUSLocales },
      'es-ES': { translations: esESLocales },
      'es-US': { translations: esUSLocales },
      'fr-CA': { translations: frCALocales },
      'fr-FR': { translations: frFRLocales },
      'it-IT': { translations: itITLocales },
      'ko-KR': { translations: koKRLocales },
      'nl-NL': { translations: nlNLLocales },
      'pl-PL': { translations: plPLLocales },
      'pt-BR': { translations: ptBRLocales },
      'pt-PT': { translations: ptPTLocales },
      'sr-RS': { translations: srRSLocales },
      'sv-SE': { translations: svSELocales },
      'ar': { translations: arILLocales },
      'bg': { translations: bgBGLocales },
      'he': { translations: heILLocales },
      'de': { translations: deDELocales },
      'en': { translations: enUSLocales },
      'es': { translations: esUSLocales },
      'fr': { translations: frFRLocales },
      'it': { translations: itITLocales },
      'ko': { translations: koKRLocales },
      'nl': { translations: nlNLLocales },
      'pl': { translations: plPLLocales },
      'pt': { translations: ptPTLocales },
      'sr': { translations: srRSLocales },
      'sv': { translations: svSELocales },
    },
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
