const koKR= {
    login: {
      title: '로그인',
      sign_in_btn: '로그인'
    },
    bar: {
        logout: '로그아웃',
        languages: '언어',
        settings: '설정 열기'
    },
    language: {
      name: '한국어(한국)',
    },
    attributePage: {
        title: '속성 변경사항',
        viewChanges: '변경사항 보기',
        dateTime: '날짜',
        notificationType: '알림 유형',
        notificationValue: '알림 값',
        oldValue: '기존 값',
        newValue: '신규 값',
        accept: '수락',
        reject: '거절',
    },
    Home: '홈',
    load: '초기 로딩 실행 중...',
    Requested: "요청됨",
    Viewed: "열람함",
    Executing: "실행 중",
    Completed: "완료됨",
    Expired: "만료됨",
    Cancelled: "취소됨",
    Error: "오류",
    Rejected: "거절됨",
    error: '문제가 생겼습니다. 나중에 다시 시도하십시오.',
    displayname: '표시 이름',
    givenname: '이름',
    surname: '성',
    email: '이메일',
    mail: '이메일',
    name: '이름',
    firstname: '이름',
    lastname: '성',
    footer_copyright: '모든 권리 보유.'
  };
  
  export default koKR;
  