const enAU= {
    login: {
      title: 'Sign in',
      sign_in_btn: 'Sign in'
    },
    bar: {
        logout: 'Log out',
        languages: 'Languages',
        settings: 'Open settings'
    },
    language: {
      name: 'English (AU)',
    },
    attributePage: {
        title: 'Attributes Changes',
        viewChanges: 'See Changes',
        dateTime: 'Date',
        notificationType: 'Notification Type',
        notificationValue: 'Notification Value',
        oldValue: 'Old Value',
        newValue: 'New Value',
        accept: 'Accept',
        reject: 'Reject',
    },
    Home: 'Home',
    load: 'Performing initial load...',
    Requested: "Requested",
    Viewed: "Viewed",
    Executing: "Executing",
    Completed: "Completed",
    Expired: "Expired",
    Cancelled: "Cancelled",
    Error: "Error",
    Rejected: "Rejected",
    error: 'Something went wrong, please try again later.',
    displayname: 'Display Name',
    givenname: 'Name',
    surname: 'Last name',
    email: 'Email',
    mail: 'Email',
    name: 'Name',
    firstname: 'Name',
    lastname: 'Last name',
    footer_copyright: 'All rights reserved.'
  };
  
  export default enAU;
  