import { createContext, useEffect, useContext, useMemo, useCallback } from 'react';
// hooks
import useLocalStorage from '../../hooks/useLocalStorage';
// utils
import localStorageAvailable from '../../utils/localStorageAvailable';
//
import { defaultSettings } from './config-setting';
import { SettingsContextProps } from './types';

const LanguagesRTL =  ['ar','ar-IL','he','he-IL'];
// ----------------------------------------------------------------------

const initialState: SettingsContextProps = {
  ...defaultSettings,
  onToggleDirection: () => {},
  onChangeDirection: () => {},
  onChangeDirectionByLang: () => {},
  // Reset
  onResetSetting: () => {},
};

// ----------------------------------------------------------------------

export const SettingsContext = createContext(initialState);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);

  if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};

// ----------------------------------------------------------------------

type SettingsProviderProps = {
  children: React.ReactNode;
};

export function SettingsProvider({ children }: SettingsProviderProps) {
  const [settings, setSettings] = useLocalStorage('settings', defaultSettings);

  const storageAvailable = localStorageAvailable();

  const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : '';

  //const isArabic = langStorage === 'ar';
  const isRTL = LanguagesRTL.includes(langStorage ?? "");
  
  
  useEffect(() => {
    if (isRTL) {
      //onChangeDirectionByLang('ar');
      onChangeDirectionByLang(langStorage ?? 'ar');
    }
    
  }, [isRTL]);

  // Direction
  const onToggleDirection = useCallback(() => {
    console.log('ToggleDirection');
    const themeDirection = settings.themeDirection === 'rtl' ? 'ltr' : 'rtl';
    setSettings({ ...settings, themeDirection });
  }, [setSettings, settings]);

  const onChangeDirection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log('onChangeDirection');
      const themeDirection = event.target.value;
      setSettings({ ...settings, themeDirection });
    },
    [setSettings, settings]
  );

  const onChangeDirectionByLang = useCallback(
    (lang: string) => {
      //const themeDirection = lang === 'ar' ? 'rtl' : 'ltr';
      const themeDirection = LanguagesRTL.includes(lang) ? 'rtl' : 'ltr';
      setSettings({ ...settings, themeDirection });
    },
    [setSettings, settings]
  );

  // Reset
  const onResetSetting = useCallback(() => {
    setSettings(defaultSettings);
  }, [setSettings]);

  const memoizedValue = useMemo(
    () => ({
      ...settings,
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      onResetSetting,
    }),
    [
      settings,
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      onResetSetting,
    ]
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}
