// @mui
import { arSA, bgBG,deDE, heIL, esES, enUS, frFR, itIT, nlNL, plPL, ptPT, ptBR, koKR, srRS, svSE, zhCN } from '@mui/material/locale';

export const allLangs = [
  {
    label: 'Arabic (Israel)',
    value: 'ar-IL',
    systemValue: arSA,
    icon: '',
  },
  {
    label: 'Bulgarian (Bulgaria)',
    value: 'bg-BG',
    systemValue: bgBG,
    icon: '',
  },
  {
    label: 'German (Germany)',
    value: 'de-DE',
    systemValue: deDE,
    icon: '',
  },
  {
    label: 'English (Australia)',
    value: 'en-AU',
    systemValue: enUS,
    icon: '',
  },
  {
    label: 'English (Canada)',
    value: 'en-CA',
    systemValue: enUS,
    icon: '',
  },
  {
    label: 'English (Ireland)',
    value: 'en-IE',
    systemValue: enUS,
    icon: '',
  },
  {
    label: 'English (United Kingdom)',
    value: 'en-GB',
    systemValue: enUS,
    icon: '',
  },
  {
    label: 'English (United States)',
    value: 'en-US',
    systemValue: enUS,
    icon: '',
  },
  {
    label: 'French (Canada)',
    value: 'fr-CA',
    systemValue: frFR,
    icon: '',
  },
  {
    label: 'French (France)',
    value: 'fr-FR',
    systemValue: itIT,
    icon: '',
  },
  {
    label: 'Hebrew (Israel)',
    value: 'he-IL',
    systemValue: heIL,
    icon: '',
  },  
  {
    label: 'Italian (Italy)',
    value: 'it-IT',
    systemValue: itIT,
    icon: '',
  },
  {
    label: 'Korean (Korea)',
    value: 'ko-KR',
    systemValue: koKR,
    icon: '',
  },
  {
    label: 'Dutch (Netherlands)',
    value: 'nl-NL',
    systemValue: nlNL,
    icon: '',
  },
  {
    label: 'Polish (Poland)',
    value: 'pl-PL',
    systemValue: plPL,
    icon: '',
  },
  {
    label: 'Portuguese (Brasil)',
    value: 'pt-BR',
    systemValue: ptBR,
    icon: '',
  },
  {
    label: 'Portuguese (Portugal)',
    value: 'pt-PT',
    systemValue: ptPT,
    icon: '',
  },
  {
    label: 'Serbian (Serbia)',
    value: 'sr-RS',
    systemValue: srRS,
    icon: '',
  },
  {
    label: 'Spanish (Spain)',
    value: 'es-ES',
    systemValue: esES,
    icon: '',
  },
  {
    label: 'Spanish (United States)',
    value: 'es-US',
    systemValue: esES,
    icon: '',
  },
  {
    label: 'Swedish (Sweden)',
    value: 'sv-SE',
    systemValue: svSE,
    icon: '',
  },   
  {
    label: '',
    value: 'ar',
    systemValue: arSA,
    icon: '',
  },
  {
    label: '',
    value: 'bg',
    systemValue: bgBG,
    icon: '',
  },
  {
    label: '',
    value: 'he',
    systemValue: heIL,
    icon: '',
  },
  {
    label: '',
    value: 'de',
    systemValue: deDE,
    icon: '',
  },
  {
    label: '',
    value: 'es',
    systemValue: esES,
    icon: '',
  },
  {
    label: '',
    value: 'fr',
    systemValue: frFR,
    icon: '',
  },
  {
    label: '',
    value: 'it',
    systemValue: itIT,
    icon: '',
  },
  {
    label: '',
    value: 'ko',
    systemValue: koKR,
    icon: '',
  },
  {
    label: '',
    value: 'nl',
    systemValue: nlNL,
    icon: '',
  },
  {
    label: '',
    value: 'pl',
    systemValue: plPL,
    icon: '',
  },
  {
    label: '',
    value: 'pt',
    systemValue: ptPT,
    icon: '',
  },
  {
    label: '',
    value: 'sr',
    systemValue: srRS,
    icon: '',
  },
  {
    label: '',
    value: 'sv',
    systemValue: svSE,
    icon: '',
  },
  // {
  //   label: '中國傳統的）',
  //   value: 'cn',
  //   systemValue: zhCN,
  //   icon: '/assets/icons/flags/ic_flag_cn.svg',
  // },
  // {
  //   label: 'عربي',
  //   value: 'ar',
  //   systemValue: arSA,
  //   icon: '/assets/icons/flags/ic_flag_sa.svg',
  // }
];

//export const defaultLang = allLangs[0]; // English
export const defaultLang = allLangs.filter(lang => {return lang.value === "en-US"})[0]; // English
