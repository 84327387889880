const bgBG= {
    login: {
      title: 'Влизане',
      sign_in_btn: 'Влизане'
    },
    bar: {
        logout: 'Излизане',
        languages: 'Езици',
        settings: 'Отворете настройките'
    },
    language: {
      name: 'български (BG)',
    },
    attributePage: {
        title: 'Промени на атрибутите',
        viewChanges: 'Виж промените',
        dateTime: 'Дата',
        notificationType: 'Тип известие',
        notificationValue: 'Стойност на известието',
        oldValue: 'Стара стойност',
        newValue: 'Нова стойност',
        accept: 'Приемане',
        reject: 'Отхвърляне',
    },
    Home: 'Начало',
    load: 'Извършване на първоначално зареждане...',
    Requested: "Заявен",
    Viewed: "Прегледан",
    Executing: "Изпълнява се",
    Completed: "Завършен",
    Expired: "С изтекла валидност",
    Cancelled: "Отменен",
    Error: "Грешка",
    Rejected: "Отхвърлен",
    error: 'Нещо се обърка. Моля, опитайте отново по-късно.',
    displayname: 'Показване на името',
    givenname: 'Име',
    surname: 'Фамилия',
    email: 'електронна поща',
    mail: 'електронна поща',
    name: 'Име',
    firstname: 'Име',
    lastname: 'Фамилия',
    footer_copyright: 'Всички права запазени.'
  };
  
  export default bgBG;
  