const frFR= {
    login: {
      title: 'Ouvrir une session',
      sign_in_btn: 'Ouvrir une session'
    },
    bar: {
        logout: 'Fermer la session',
        languages: 'Langues',
        settings: 'Ouvrir les paramètres'
    },
    language: {
      name: 'Français (FR)',
    },
    attributePage: {
        title: 'Changements d’attributs',
        viewChanges: 'Voir les changements',
        dateTime: 'Date',
        notificationType: 'Type de notification',
        notificationValue: 'Valeur de la notification',
        oldValue: 'Ancienne valeur',
        newValue: 'Nouvelle valeur',
        accept: 'Accepter',
        reject: 'Rejeter',
    },
    Home: 'Accueil',
    load: 'Exécution du chargement initial...',
    Requested: "Demandé",
    Viewed: "Visualisé",
    Executing: "Exécution en cours",
    Completed: "Terminé",
    Expired: "Expiré",
    Cancelled: "Annulé",
    Error: "Erreur",
    Rejected: "Rejeté",
    error: 'Un problème est survenu ; veuillez réessayer plus tard.',
    displayname: 'Afficher nom',
    givenname: 'Nom',
    surname: 'Nom de famille',
    email: 'E-mail',
    mail: 'E-mail',
    name: 'Nom',
    firstname: 'Nom',
    lastname: 'Nom de famille',
    footer_copyright: 'Tous droits réservés.'
  };
  
  export default frFR;
  