const nlNL= {
    login: {
      title: 'Inloggen',
      sign_in_btn: 'Inloggen'
    },
    bar: {
        logout: 'Uitloggen',
        languages: 'Talen',
        settings: 'Open instellingen'
    },
    language: {
      name: 'Nederlands (NL)',
    },
    attributePage: {
        title: 'Wijzigingen gegevens',
        viewChanges: 'Wijzigingen bekijken',
        dateTime: 'Datum',
        notificationType: 'Meldingstype',
        notificationValue: 'Meldingswaarde',
        oldValue: 'Oude waarde',
        newValue: 'Nieuwe waarde',
        accept: 'Accepteren',
        reject: 'Afwijzen',
    },
    Home: 'Startpagina',
    load: 'Bezig met initieel laden...',
    Requested: "Aangevraagd",
    Viewed: "Bekeken",
    Executing: "Wordt uitgevoerd",
    Completed: "Voltooid",
    Expired: "Verlopen",
    Cancelled: "Geannuleerd",
    Error: "Fout",
    Rejected: "Afgewezen",
    error: 'Er is iets fout gegaan. Probeer het later nog eens.',
    displayname: 'Naam op scherm',
    givenname: 'Naam',
    surname: 'Achternaam',
    email: 'E-mail',
    mail: 'E-mail',
    name: 'Naam',
    firstname: 'Naam',
    lastname: 'Achternaam',
    footer_copyright: 'Alle rechten voorbehouden.'
  };
  
  export default nlNL;
  