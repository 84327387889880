import localStorageAvailable from '../utils/localStorageAvailable';
import { allLangs, defaultLang } from './config-lang';

// ----------------------------------------------------------------------

export default function useLocalesInit() {

  const storageAvailable = localStorageAvailable();

  const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : '';

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;
  
  const currentLangValue = currentLang.value;

  return {
    currentLangValue : currentLangValue,
  };
}
